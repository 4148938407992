import React, { useState, useEffect } from "react";
import axiosInstance from "../../API/axiosSetup";
import api_url from "../../API/api";
import AddProduct from "./AddProduct";
import "./ProductList.css";

function ProductList() {
  const [products, setProducts] = useState([]);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get(`${api_url}/api/v1/prods/`);
      
      if (response.data.success) {
        setProducts(response.data.products);
      } else {
        setError("Failed to fetch products.");
      }
    } catch (error) {
      setError("An error occurred while fetching products.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddProduct = () => {
    setSelectedProduct(null); // Reset the selected product when adding a new one
    setShowAddProduct(true);
  };

  const handleProductAdded = () => {
    setShowAddProduct(false);
    fetchProducts(); // Refresh the product list after adding or editing a product
  };

  const handleCancel = () => {
    setShowAddProduct(false);
  };

  const handleEdit = (productId) => {
    const productToEdit = products.find((product) => product.ID === productId);
    setSelectedProduct(productToEdit); // Set the selected product for editing
    setShowAddProduct(true);
  };

  const handleDelete = async (productId) => {
    try {
      await axiosInstance.post(`${api_url}/api/v1/deleteprod/`, {
        product_id: productId,
      });
      fetchProducts(); // Refresh the product list after deleting
    } catch (error) {
      setError("An error occurred while deleting the product.");
    }
  };

  const filteredProducts = products.filter(
    (product) =>
      product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.division_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="product-list-container">
      {showAddProduct ? (
        <AddProduct
          onProductAdded={handleProductAdded}
          onCancel={handleCancel}
          product={selectedProduct} // Pass the selected product for editing
        />
      ) : (
        <>
          <div className="search-bar">
            <div className="input-group search-text-box">
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button onClick={handleAddProduct} className="add-product-button">
              Add New Product
            </button>
          </div>

          {loading ? (
            <div className="loader"></div>
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : (
            <div className="product-table-container">
              <table className="product-table">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Category</th>
                    <th>Division</th>
                    <th>Price</th>
                    <th>Contains</th>
                    <th>Package</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts.map((product) => (
                    <tr key={product.ID}>
                      <td>{product.product_name}</td>
                      <td>{product.category_name}</td>
                      <td>{product.division_name}</td>
                      <td>{product.price}</td>
                      <td>{product.contains}</td>
                      <td>{product.pkg}</td>
                      <td>
                        <span
                          className={`status ${
                            product.product_status === 0 ? "active" : "inactive"
                          }`}
                        >
                          {product.product_status === 0 ? "Active" : "Inactive"}
                        </span>
                      </td>
                      <td>
                      <button className="edit-button" onClick={() => handleEdit(product.ID)}>
                      <i className="fa fa-edit"></i>
                        </button>
                        <button className="delete-button" onClick={() => handleDelete(product.ID)}>
                        <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ProductList;
