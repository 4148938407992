import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CategoryMaster from "./Admin/Category/CategoryMaster";
import Login from "./Login/Login";
import HomePage from "./Admin/Home/HomePage";
import ProductList from "./Admin/Product/ProductList";
import ChangePassword from "./Admin/Password/ChangePassword";
import ProtectedRoute from "./API/ProtectedRoute";
import Home from "./Customer/Home";
//Nq%Xj=)E-*??
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/admin_home"
          element={<ProtectedRoute element={<HomePage />} />}
        />
        <Route
          path="/categories"
          element={<ProtectedRoute element={<CategoryMaster />} />}
        />
        <Route
          path="/divisions"
          element={<ProtectedRoute element={<CategoryMaster />} />}
        />
        <Route
          path="/products"
          element={<ProtectedRoute element={<ProductList />} />}
        />
        <Route
          path="/change-password"
          element={<ProtectedRoute element={<ChangePassword />} />}
        />
        <Route
          path="/customer_home"
          element={<ProtectedRoute element={<Home />} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
