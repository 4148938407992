import React from "react";
import "./ProductCard.css"; // Ensure you have this CSS file for styling

const ProductCard = ({
  product_name,
  category_name,
  division_name,
  pkg,
  price,
  contains,
  notes,
}) => {
  return (
    <div className="product-card">
      {/* Add the logo here */}
      <img src="/logo.jpg" alt="Logo" className="product-logo" />
      <div className="product-header">
        <h2 className="product-name">{product_name}</h2>
      </div>
      <div className="product-details">
        <p className="details-line">{contains}</p>
        <div className="pkg-price-detail">
          <span className="pkg">{pkg}</span>
          {/* <span className="price">₹ {price}</span> */}
        </div>
        <p className="details-line division">{division_name}</p>
        {notes && <p className="details-line notes">{notes}</p>}
      </div>
    </div>
  );
};

export default ProductCard;
