import React from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Show confirmation alert
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    
    if (confirmLogout) {
      // Clear token from local storage
      localStorage.removeItem('token');

      // Redirect to login page
      navigate('/');
    }
  };
  return (
    <div className="home-page">
      <h1>Admin Dashboard</h1>
      <div className="button-container">
        <Link to="/categories" state={{ isCategory: true }} className="dashboard-button">
          Category
        </Link>
        <Link to="/divisions" state={{ isCategory: false }} className="dashboard-button">
          Division
        </Link>
        <Link to="/products" className="dashboard-button">
          Product
        </Link>
        <Link
          to="/change-password"
          state={{ isAdmin: false }}
          className="dashboard-button"
        >
          Change User Password
        </Link>
        <Link
          to="/change-password"
          state={{ isAdmin: true }}
          className="dashboard-button"
        >
          Change Admin Password
        </Link>
        <Link
          to="/customer_home"
          className="dashboard-button"
        >
          Customer Page
        </Link>
        <button className="dashboard-button" onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
}

export default HomePage;
