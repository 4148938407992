import React, { useState, useEffect } from 'react';
import ProductCard from './ProductCard';
import './Home.css'; // Ensure you have this CSS file for styling
import axiosInstance from "../API/axiosSetup";
import api_url from "../API/api";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);

  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const response = await axiosInstance.get(`${api_url}/api/v1/divs/`);
        setDivisions(response.data.divisions);
      } catch (error) {
        console.error('Error fetching divisions:', error);
        setError('Failed to load divisions.');
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.get(`${api_url}/api/v1/prods/`);
        const data = response.data.products;
        setProducts(data);
        setFilteredProducts(data); // Initialize with all products
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products.');
      } finally {
        setLoading(false);
      }
    };

    fetchDivisions();
    fetchProducts();
  }, []);

  const handleDivisionClick = (division) => {
    setFilteredProducts(products.filter(product => product.division_name === division.division_name));
    setSelectedDivision(division.division_name);
  };

  const handleBackClick = () => {
    setSelectedDivision(null);
    setFilteredProducts(products);
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="loading">{error}</div>;
  }

  return (
    <div className="home-container">
      {!selectedDivision ? (
        <div className="division-filter">
          {divisions.map((division) => (
            <button
              key={division.ID}
              className="filter-tab"
              onClick={() => handleDivisionClick(division)}
            >
              {division.division_name}
            </button>
          ))}
        </div>
      ) : (
        <>
          <div className="division-header">
            <button className="back-button" onClick={handleBackClick}>
              &larr; Back
            </button>
            <h2>{selectedDivision}</h2>
          </div>
          <div className="product-list">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => (
                <ProductCard key={product.ID} {...product} />
              ))
            ) : (
              <div className="no-products">Currently no products available.</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
