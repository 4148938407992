import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CategoryMaster.css';
import api_url from '../../API/api';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useLocation } from 'react-router-dom';


function Loader() {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
}

function CategoryMaster() {
  const location = useLocation();
  const isCategory = location.state?.isCategory;
  // const [isCategory, setIsCategory] = useState(true); // Track if we are dealing with categories or divisions
  const [items, setItems] = useState([]);
  const [newItemName, setNewItemName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchItems();
  }, [isCategory]);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const endpoint = isCategory ? '/api/v1/cats/' : '/api/v1/divs/';
      const response = await axios.get(`${api_url}${endpoint}`, {
        headers: { 'X-Access-Token': token }
      });
      setItems(response.data.categories || response.data.divisions);
    } catch (error) {
      setError('Error fetching items');
    } finally {
      setLoading(false);
    }
  };

  const handleAddItem = async () => {
    if (newItemName.trim() === '') return;
    setLoading(true);
    try {
      const endpoint = isCategory ? '/api/v1/createcat/' : '/api/v1/creatediv/';
      const response = await axios.post(`${api_url}${endpoint}`, 
        { [isCategory ? 'category' : 'division_name']: newItemName },
        {
          headers: { 'X-Access-Token': token }
        }
      );
      setItems([...items, response.data.category || response.data.division]);
      setNewItemName('');
    } catch (error) {
      setError('Error adding item');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteItem = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      setLoading(true);
      try {
        const endpoint = isCategory ? '/api/v1/deletecat/' : '/api/v1/deletediv/';
        await axios.post(`${api_url}${endpoint}`, { [`${isCategory ? 'cat' : 'division'}_id`]: id }, {
          headers: { 'X-Access-Token': token }
        });
        setItems(items.filter(item => item.ID !== id));
      } catch (error) {
        setError('Error deleting item');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditItem = async (id) => {
    const editedName = prompt('Enter new name:');
    if (editedName && editedName.trim() !== '') {
      setLoading(true);
      try {
        const endpoint = isCategory ? '/api/v1/updatecat/' : '/api/v1/updatediv/';
        await axios.post(`${api_url}${endpoint}`, 
          { [isCategory ? 'category' : 'division_name']: editedName, [`${isCategory ? 'cat' : 'division'}_id`]: id },
          {
            headers: { 'X-Access-Token': token }
          }
        );
        setItems(items.map(item => 
          item.ID === id ? { ...item, [isCategory ? 'category_name' : 'division_name']: editedName } : item
        ));
      } catch (error) {
        setError('Error editing item');
      } finally {
        setLoading(false);
      }
    }
  };

  // Filter items based on search query
  const filteredItems = items.filter(item =>
    (item.category_name || item.division_name).toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="category-master">
      <h2>{isCategory ? 'Category Master' : 'Division Master'}</h2>
      <div className="add-category">
        <input 
          type="text" 
          placeholder={`Add ${isCategory ? 'Category' : 'Division'}`} 
          value={newItemName} 
          onChange={(e) => setNewItemName(e.target.value)} 
        />
        <button onClick={handleAddItem} disabled={loading}>Add</button>
      </div>
      <div className="search-bar-cat">
        <input 
          type="text" 
          placeholder={`Search ${isCategory ? 'Categories' : 'Divisions'}...`} 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
          
        />
      </div>
      {loading ? <Loader /> : (error ? <p className="error">{error}</p> : (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>{isCategory ? 'Category Name' : 'Division Name'}</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map(item => (
                <tr key={item.ID}>
                  <td>{item.ID}</td>
                  <td>{item[isCategory ? 'category_name' : 'division_name']}</td>
                  <td>
                    <button className="edit-button" onClick={() => handleEditItem(item.ID)} disabled={loading}>
                      <i className="fa fa-edit"></i>
                    </button>
                    <button className="delete-button" onClick={() => handleDeleteItem(item.ID)} disabled={loading}>
                      <i className="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}

    </div>
  );
}

export default CategoryMaster;
