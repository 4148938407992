import React, { useState, useEffect } from 'react';
import axiosInstance from '../../API/axiosSetup';
import api_url from '../../API/api';
import './AddProduct.css';

function AddProduct({ onProductAdded, onCancel, product }) {
  const [productName, setProductName] = useState(product ? product.product_name : '');
  const [categoryId, setCategoryId] = useState(product ? product.category_id : '');
  const [categoryName, setCategoryName] = useState(product ? product.category_name : '');
  const [divisionId, setDivisionId] = useState(product ? product.division_id : '');
  const [divisionName, setDivisionName] = useState(product ? product.division_name : '');
  const [price, setPrice] = useState(product ? product.price : '');
  const [notes, setNotes] = useState(product ? product.notes : '');
  const [contains, setContains] = useState(product ? product.contains : '');
  const [status, setStatus] = useState(product ? product.product_status : 0);
  const [pkg, setPkg] = useState(product ? product.pkg : '');
  const [categories, setCategories] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
    fetchDivisions();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get(`${api_url}/api/v1/cats/`);
      if (response.data.success) {
        setCategories(response.data.categories);
      } else {
        setError('Failed to fetch categories.');
      }
    } catch (error) {
      setError('An error occurred while fetching categories.');
    }
  };

  const fetchDivisions = async () => {
    try {
      const response = await axiosInstance.get(`${api_url}/api/v1/divs/`);
      if (response.data.success) {
        setDivisions(response.data.divisions);
      } else {
        setError('Failed to fetch divisions.');
      }
    } catch (error) {
      setError('An error occurred while fetching divisions.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    
    try {
      const data = {
        product_name: productName,
        category_id: categoryId,
        category_name: categoryName,
        division_id: divisionId,
        division_name: divisionName,
        product_status: status,
        pkg: pkg,
        price: price,
        contains: contains,
        notes: notes,
      };

      const url = product ? `${api_url}/api/v1/updateprod/` : `${api_url}/api/v1/createprod/`;
      const response = await axiosInstance.post(url, {
        ...data,
        product_id: product ? product.ID : undefined,
      });

      if (response.data.success) {
        setSuccess(product ? 'Product updated successfully!' : 'Product added successfully!');
        setProductName('');
        setCategoryId('');
        setCategoryName('');
        setDivisionId('');
        setDivisionName('');
        setPrice('');
        setNotes('');
        setContains('');
        setStatus(0);
        setPkg('');
        onProductAdded();
      } else {
        setError(product ? 'Failed to update product. Please try again.' : 'Failed to add product. Please try again.');
      }
    } catch (error) {
      setError(product ? 'An error occurred during product update.' : 'An error occurred during product creation.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-product-container">
      <h2>{product ? 'Edit Product' : 'Add Product'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="productName">Product Name</label>
          <input
            type="text"
            id="productName"
            name="productName"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="contains">Contains</label>
          <input
            type="text"
            id="contains"
            name="contains"
            value={contains}
            onChange={(e) => setContains(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="categoryId">Category</label>
          <select
            id="categoryId"
            name="categoryId"
            value={categoryId}
            onChange={(e) => {
              const selectedCategory = categories.find(c => c.ID == e.target.value);
              setCategoryId(e.target.value);
              setCategoryName(selectedCategory ? selectedCategory.category_name : '');
            }}
            required
          >
            <option value="">Select Category</option>
            {categories.map(category => (
              <option key={category.ID} value={category.ID}>{category.category_name}</option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="divisionId">Division</label>
          <select
            id="divisionId"
            name="divisionId"
            value={divisionId}
            onChange={(e) => {
              const selectedDivision = divisions.find(d => d.ID == e.target.value);
              setDivisionId(e.target.value);
              setDivisionName(selectedDivision ? selectedDivision.division_name : '');
            }}
            required
          >
            <option value="">Select Division</option>
            {divisions.map(division => (
              <option key={division.ID} value={division.ID}>{division.division_name}</option>
            ))}
          </select>
        </div>
        <div className="input-group-inline">
          <div className="inline-group">
            <label htmlFor="pkg">Package</label>
            <input
              type="text"
              id="pkg"
              name="pkg"
              value={pkg}
              onChange={(e) => setPkg(e.target.value)}
            />
          </div>
          <div className="inline-group">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              id="price"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
          <div className="inline-group">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
            >
              <option value="">Select Status</option>
              <option value={0}>Active</option>
              <option value={1}>Inactive</option>
            </select>
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="notes">Notes</label>
          <textarea
            id="notes"
            name="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? <div className="loader"></div> : product ? 'Edit Product' : 'Add Product'}
        </button>
        <button type="button" className="submit-button" onClick={onCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
}

export default AddProduct;
