import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import axiosInstance from '../../API/axiosSetup'; 
import api_url from '../../API/api'; 
import './ChangePassword.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS

function ChangePassword() {
  const location = useLocation(); // Get location object
  const navigate = useNavigate(); // Initialize useNavigate
  const isAdmin = location.state?.isAdmin; // Retrieve isAdmin from location.state

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [id, setId] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [showPassword, setShowPassword] = useState(false); // Show/Hide password state

  useEffect(() => {
    if (isAdmin) {
      setUsername('admin');
      setId(1);
    } else {
      fetchUsername();
    }
  }, [isAdmin]);

  const fetchUsername = async () => {
    try {
      const response = await axiosInstance.get(`${api_url}/api/v1/username/`);
      if (response.data.success) {
        setUsername(response.data.user.username);
        setPassword(response.data.user.pwd);
        setId(response.data.user.ID);
      } else {
        setError('Failed to fetch username.');
      }
    } catch (error) {
      setError('An error occurred while fetching username.');
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true); // Show "Updating..." text
    try {
      const response = await axiosInstance.post(`${api_url}/api/v1/updateuser/`, {
        'user_id': id,
        'username': username,
        'pwd': password,
      });
      if (response.data.success) {
        alert('Password changed successfully!');
        setTimeout(() => {
          navigate('/admin_home'); // Redirect to home page
        }, 2000); // Adjust the delay as needed
      } else {
        setError('Failed to change password.');
      }
    } catch (error) {
      setError('An error occurred while changing the password.');
    } finally {
      setLoading(false); // Hide "Updating..." text
    }
  };

  return (
    <div className="change-password-container">
      <h2>Change {isAdmin ? 'Admin' : 'User'} Password</h2>
      <form onSubmit={handlePasswordChange}>
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={isAdmin || loading}
        />

        <label htmlFor="password">New Password</label>
        <div className="password-container">
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
          <span
            className="password-toggle-icon"
            onClick={() => setShowPassword(!showPassword)}
          >
            <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
          </span>
        </div>

        {error && <p className="error-message">{error}</p>}

        <button type="submit" disabled={loading}>
          {loading ? 'Updating...' : 'Change Password'}
        </button>
      </form>
    </div>
  );
}

export default ChangePassword;
